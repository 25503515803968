import React, { useEffect, useState } from "react";
import SinglePost from './singlePost'
import NewPost from './newPost'


const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [sortType, setSortType] = useState("Date");

  const updatePosts = async () =>{
    const resp = await fetch("https://serverless-api.goncalo-gil.workers.dev/api/posts");
    const postsResp = await resp.json();
    if(sortType === "Likes"){
      postsResp.sort((a,b) => {return Number(b.likes) - Number(a.likes)})
    }
    setPosts(postsResp);
  }

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch("https://serverless-api.goncalo-gil.workers.dev/api/posts");
      const postsResp = await resp.json();
      if(sortType === "Likes"){
        postsResp.sort((a,b) => {return Number(b.likes) - Number(a.likes)})
      }
      setPosts(postsResp);
    };
    getPosts();
  }, [sortType]);



  return (
    <div class="container bootstrap snippets bootdey">
        <NewPost updatePosts={() => updatePosts() }/>
        <div class="col-md-6">        
        <div class="dropdown">
          <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Sorted by {sortType}
            <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li><button class="btn-as-a" onClick={() => setSortType("Date")}>Date</button></li>
            <li><button class="btn-as-a" onClick={() => setSortType("Likes")}>Likes</button></li>
          </ul>
        </div>        
        {posts.map((post) => {return <SinglePost value={post} updatePosts={() => updatePosts()}/>;} )}
        </div>  
    </div>
  );
};




export default Posts;