import React, { useState } from "react";

function NewPost({updatePosts}){
  const [creatorPost, setCreatorPost] = useState("");
  const [titlePost, setTitlePost] = useState("");
  const [contPost, setContPost] = useState("");
  const [imageLink, setImageLink] = useState("https://bootdey.com/img/Content/user_3.jpg");
  const handleCreator = event => {setCreatorPost(event.target.value)};
  const handleTitle = event => {setTitlePost(event.target.value)}
  const handleCont = event => {setContPost(event.target.value)}
  const handleImageLink = event => {(isUriImage(event.target.value))? setImageLink(event.target.value):setImageLink("https://bootdey.com/img/Content/user_3.jpg")}
  

  const PublishPost = async () =>{
    if(creatorPost.length === 0 || titlePost.length === 0 || contPost.length === 0){
      alert("Please fill all fields");
      return
    };

    const newPost = {
      "image": imageLink,
      "creator": creatorPost,
      "title": titlePost,
      "content": contPost      
    };

    const options = {
      method: 'POST',
      body: JSON.stringify(newPost)
    };
    
    document.getElementById("creatorform").value="";
    document.getElementById("imageform").value="";
    document.getElementById("titleform").value="";
    document.getElementById("contentform").value="";
    setImageLink("https://bootdey.com/img/Content/user_3.jpg");
    
    await fetch("https://serverless-api.goncalo-gil.workers.dev/api/addPost",options);
    updatePosts();
    return
  };

  return(
    <div class="col-md-6">
    <div class="panel panel-white post panel-shadow">
      <div class="post-heading">
        <div class="pull-left image">
          <img src={imageLink} class="img-circle avatar" alt="user profile"/>
        </div>
        <div>
          <div class="pull-left meta">
            <input id="creatorform" class="form-control form-control-name" onChange={handleCreator} placeholder="Who are you?" type="text" required minLength="1"/>
            <input id="imageform" class="form-control form-control-name" onChange={handleImageLink} placeholder="Insert an image link of you" type="text" required minLength="1"/>
          </div>                  
        </div>
      </div>
      <div class="post-description">
        <input id="titleform" class="form-control form-control-title" onChange={handleTitle} placeholder="Insert your title" type="text" required minLength="1"/>
        <input id="contentform" class="form-control" onChange={handleCont}  placeholder="Insert your description" type="text" required minLength="1"/>              
      </div>
      <div class="post-description">
          <button onClick={PublishPost} type="button" class="btn stat-item post-button" >
            <i class="fa fa-paper-plane"/>
          </button>
      </div>
    </div>
  </div>
  )


}

function isUriImage(uri) {
  //make sure we remove any nasty GET params 
  uri = uri.split('?')[0];
  //moving on, split the uri into parts that had dots before them
  var parts = uri.split('.');
  //get the last part ( should be the extension )
  var extension = parts[parts.length-1];
  //define some image types to test against
  var imageTypes = ['jpg','jpeg','tiff','png','gif','bmp'];
  //check if the extension matches anything in the list.
  if(imageTypes.indexOf(extension) !== -1) {
      return true;   
  }
}

export default NewPost