import React from "react";

const SinglePost = (props) => {  
    const date = props.value.date.slice(0,19)

    const likePost = async (id) =>{
        await fetch(`https://serverless-api.goncalo-gil.workers.dev/api/likes/${id}`);
        props.updatePosts()
    }

    return (
        <div class="panel panel-white post panel-shadow">
            <div class="post-heading">
                <div class="pull-left image">
                    <img src={props.value.image} class="img-circle avatar" alt="user profile"/>
                </div>
                <div class="pull-left meta">
                    <div class="title h5">
                        <p><b>{props.value.creator}</b></p>
                    </div>
                    <h6 class="text-muted time">{date}</h6>
                </div>
            </div> 
            <div class="post-description">
                <p><b>{props.value.title}</b></p> 
                <p>{props.value.content}</p>
                <div class="stats">
                    <button onClick={() => likePost(props.value.id)} type="button" class="btn btn-default stat-item">
                        <i class="fa fa-thumbs-up icon"></i>{props.value.likes}
                    </button>
                </div>
            </div>
        </div>

  );
};

export default SinglePost;
